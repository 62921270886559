import request from "@/utils/request";

export function getQuestionnaireAPI(id) {
	return request({
		url: "/wx/auth/questionnaire",
		method: "get",
		params: {
			id
		}
	});
}

export function submitQuestionnaireFormAPI(data) {
	return request({
		url: "/wx/auth/questionnaireForm",
		method: "post",
		data
	});
}
