import { getQuestionnaireAPI, submitQuestionnaireFormAPI } from "@/api";
import router from "@/router";
import { showSuccessToast, showFailToast,showToast } from "vant";
export const questionnaireMoudle = {
	namespaced: true,
	state() {
		return {
			questionList: [],
			answer: 0,
			portId: null,
			title:''
		};
	},
	actions: {
		async fetchGetQuestionnaireActions({ commit }, payload) {
			const res1 = await getQuestionnaireAPI(payload);
			if (res1 && res1.code == 200) {
				commit("SET_questionList", res1.data?.questionList);
				commit("SET_answer", res1.data?.answer);
				commit("SET_portId", res1.data?.id);
				commit("SET_title", res1.data?.title);
			} else {
				showToast(res1.msg);
			}
		},
		async fetchsubmitQuestionnaireFormActions({ commit }, payload) {
			const res = await submitQuestionnaireFormAPI(payload.data);
			console.log('----------------')
			console.log(res.data[0])
					
			if (res?.code == 200) {
				router.replace({ name: "successfully", query: { id: payload.id ,data:JSON.stringify(res.data)} });
			}
		}
	},
	mutations: {
		SET_questionList(state, payload) {
			state.questionList = payload;
		},
		SET_answer(state, payload) {
			state.answer = payload;
		},
		SET_portId(state, payload) {
			state.portId = payload;
		},
		SET_title(state, payload){
			state.title = payload;
		},
	},
	getters: {}
};
