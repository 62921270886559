import { getDiscountAPI, getCouponInfoAPI, getCouponDetailAPI, getUserInfoAPI } from "@/api";
import storage from "@/utils/storage";

export const discountMoudle = {
	namespaced: true,
	state() {
		return {
			discountList: [],
			discountListTotal: 0,
			detail: "",
			boolVerification: false
		};
	},
	actions: {
		async fetchGetDiscountActions({ commit }, payload) {
			const { code, data } = await getDiscountAPI(payload);
			if (code == 200) {
				commit("SET_discountList", { data: data?.records, payload, total: data.total });
				return true;
			} else {
				return false;
			}
		},
		async fetchGetCouponInfoActions({ commit }, payload) {
			const res = await getCouponInfoAPI(payload);
			if (res.code == 200) {
				return res?.data;
			}
		},

		async fetchGetCouponDetailActions({ commit }, payload) {
			const { code, data } = await getCouponDetailAPI(payload);
			if (code == 200) {
				commit("SET_detail", data);
			}
		},
		async getUserInfoActions({ commit }) {
			const res = await getUserInfoAPI();
			commit("SET_boolVerification", res.data.boolVerification);
			storage.setCache("boolVerification", res.data.boolVerification);
		}
	},
	mutations: {
		SET_discountList(state, payload) {
			if (payload.pageNo == 1) {
				state.discountList = payload.data;
			} else {
				state.discountList = [...state.discountList, ...payload.data];
			}
			state.discountListTotal = payload.total;
		},
		SET_discountList2(state) {
			state.discountList = [];
		},
		SET_detail(state, payload) {
			state.detail = payload;
		},
		SET_boolVerification(state, payload) {
			state.boolVerification = payload;
		}
	},
	getters: {}
};
