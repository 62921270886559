import router from "@/router";
import axios from "axios";
import { showFailToast, showLoadingToast,showToast } from "vant";
import storage from "./storage";

let toast = null;
const instance = axios.create({
	baseURL: process.env.VUE_APP_BASE_API
});
// 定义请求拦截器
instance.interceptors.request.use(
	(config) => {
		toast = showLoadingToast({
			message: "加载中...",
			forbidClick: true,
			duration: 0,
			className: "myloading"
		});
		const token = storage.getCache("token");
		if (token) {
			config.headers.Authorization = token;
		}
		return config;
	},
	(error) => Promise.reject(error)
);
// 定义响应拦截器
instance.interceptors.response.use(
	(response) => {
		if (response.data && response.data.code == "10001") {
			storage.clearCache();
			router.replace({ path: "/login", query: { id: router.currentRoute.value.query.id } });
			showToast("登录失效，请重新登录");
			return;
		}
		toast.close();
		// 登录成功，放⾏
		return response.data;
	},
	(error) => {
		toast.close();
		showToast("未知错误");
		console.log(error);
		//return Promise.reject(error);
	}
);
// ⾃定义各种数据请求 axios({})
export default function request(config) {
	const { url = "", method = "GET", data = {}, headers = {}, params = {} } = config;
	switch (method.toUpperCase()) {
		// 获取数据
		case "GET":
			return instance.get(url, { params });
		// 添加数据
		case "POST":
			// 表单提交 content-type = application/x-wwH-form-url-encoded
			if (headers["content-type"] === "application/x-wwH-form-url-encoded") {
				// 转参数 URLSearchParams/第三⽅库qs
				const p = new URLSearchParams();
				for (const key in data) {
					p.append(key, data[key]);
				}
				return instance.post(url, p, { headers });
			}
			// ⽂件提交 content-type = multipart/form-data
			if (headers["content-type"] === "multipart/form-data") {
				const p = new FormData();
				for (const key in data) {
					p.append(key, data[key]);
				}
				return instance.post(url, p, { headers });
			}
			// 默认 content-type = application/json
			return instance.post(url, data);
		// 修改数据 - 所有的数据的更新
		case "PUT":
			return instance.put(url, data);
		//删除数据
		case "DELETE":
			return instance.delete(url, { data });
		// 修改数据 - 部分的数据的更新
		case "PATCH":
			return instance.patch(url, data);
		// 默认返回实例
		default:
			return instance(config);
	}
}
