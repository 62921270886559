import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "normalize.css";
import "vant/lib/index.css";
import "amfe-flexible/index.js";
import "@/permission";
import "@/assets/index.scss";
import { PullRefresh } from "vant";


createApp(App).use(store).use(router).use(PullRefresh).mount("#app");
