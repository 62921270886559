import { createStore } from "vuex";

import { loginModule } from "./moudel/loginModule";
import { questionnaireMoudle } from "./moudel/questionnaireMoudle";
import { discountMoudle } from "./moudel/discountModule";

export default createStore({
	state: {},
	getters: {},
	mutations: {},
	actions: {},
	modules: {
		login: loginModule,
		questionnaire: questionnaireMoudle,
		discount: discountMoudle
	}
});
