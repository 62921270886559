import request from "@/utils/request";

export function phoneCodeAPI(phone) {
	return request({
		url: "/wx/ma/oauth/sendLoginSms",
		method: "get",
		params: {
			phone
		}
	});
}

export function phoneLoginAPI(data) {
	return request({
		url: "/wx/ma/oauth/phoneLogin",
		method: "post",
		data
	});
}

export function getUserInfoAPI(data) {
	return request({
		url: "/wx/auth/userInfo",
		method: "get"
	});
}
