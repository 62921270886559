import request from "@/utils/request";

export function getDiscountAPI(params) {
	return request({
		url: "/wx/auth/couponByQuestionnaire",
		method: "get",
		params
	});
}

export function getCouponInfoAPI(code) {
	return request({
		url: "/wx/auth/couponInfo",
		method: "get",
		params: {
			code
		}
	});
}

export function getwxUserConfigAPI(url) {
	return request({
		url: "/wx/auth/wxUserConfig",
		method: "get",
		params: {
			url
		}
	});
}

export function verification(qrCode) {
	return request({
		url: "/wx/auth/verification",
		method: "post",
		data: {
			qrCode
		}
	});
}

export function getverificationPageAPI(params) {
	return request({
		url: "/wx/auth/verificationPage",
		method: "get",
		params
	});
}

export function getCouponDetailAPI(id) {
	return request({
		url: "/wx/auth/couponDetail",
		method: "get",
		params: {
			id
		}
	});
}

export function getUserInfoAPI() {
	return request({
		url: "/wx/auth/userInfo",
		method: "get"
	});
}
