import { phoneCodeAPI, phoneLoginAPI, getUserInfoAPI } from "@/api";
import router from "@/router";
import storage from "@/utils/storage";

import { showSuccessToast, showFailToast,showToast } from "vant";

export const loginModule = {
	namespaced: true,
	state() {
		return {
			phone: storage.getCache("phone") || "",
			token: storage.getCache("token") || "",
			boolVerification: storage.getCache("boolVerification") || ""
		};
	},
	actions: {
		async fetchGetPhoneCodeActions({ commit }, payload) {
			const res = await phoneCodeAPI(payload);
			if (res && res.code == 200) {
				showSuccessToast(res.data);
			} else {
				showToast(res.data);
			}
		},
		async fetchPhoneLoginActions({ commit }, payload) {
			const { code, msg, data } = await phoneLoginAPI(payload.form);
			if (code == 200) {
				commit("SET_LOGININFO", data);
				storage.setCache("phone", data.phone);
				storage.setCache("token", data.token);
				const res = await getUserInfoAPI();
				if (res.code == 200) {
					commit("SET_boolVerification", res.data.boolVerification);
					storage.setCache("boolVerification", res.data.boolVerification);
					router.replace({ path: "/", query: { id: payload.id } });
				}
			} else {
				showToast(msg);
			}
		}
	},
	mutations: {
		SET_LOGININFO(state, payload) {
			const { phone, token } = payload;
			state.phone = phone;
			state.token = token;
		},
		SET_boolVerification(state, payload) {
			state.boolVerification = payload;
		}
	},
	getters: {}
};
