import router from "./router/index";
import storage from "./utils/storage";

router.beforeEach((to, from, next) => {
	const token = storage.getCache("token");
	if (token) {
		// 已登录
		if (to.name === "login") {
			next({
				name: "index"
			});
		} else {
			next();
		}
	} else {
		// 未登录
		if (to.name === "login") {
			next();
		} else {
			next({
				path: "/login",
				query: { id: to.query.id },
				replace: true
			});
			//next(`/login?id=${to.query.id}`);
		}
	}
});
