import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { isWeChatBrowser } from "@/utils";
export default {
  __name: 'App',
  setup(__props) {
    const isWXbrowser = isWeChatBrowser();
    if (isWXbrowser) {
      // 在微信浏览器中打开
      // 执行相应的操作
      //console.log("是微信");
    } else {
      // 不在微信浏览器中打开
      // 执行其他操作
      //console.log("不是微信");
    }
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createBlock(_component_router_view);
    };
  }
};