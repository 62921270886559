import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
	{
		path: "/",
		name: "index",
		component: () => import(/* webpackChunkName: "index" */ "@/views/index/index")
		//	redirect: "/home"
	},
	{
		path: "/login",
		name: "login",
		component: () => import(/* webpackChunkName: "login" */ "@/views/login/index")
	},
	{
		path: "/successfully",
		name: "successfully",
		component: () => import(/* webpackChunkName: "successfully" */ "@/views/successfully/index")
	},
	{
		path: "/discount",
		name: "discount",
		component: () => import(/* webpackChunkName: "discount" */ "@/views/discount/index")
	},
	{
		path: "/discountExplain",
		name: "discountExplain",
		component: () => import(/* webpackChunkName: "discountExplain" */ "@/views/discountExplain/index")
	},
	{
		path: "/destroy",
		name: "destroy",
		component: () => import(/* webpackChunkName: "destroy" */ "@/views/destroy/index")
	},
	{
		path: "/history",
		name: "history",
		component: () => import(/* webpackChunkName: "history" */ "@/views/history/index")
	}

	// ,
	// {
	// 	path: "/about",
	// 	name: "about",
	// 	// route level code-splitting
	// 	// this generates a separate chunk (about.[hash].js) for this route
	// 	// which is lazy-loaded when the route is visited.
	// 	component: () => import(/* webpackChunkName: "about" */ "../views/AboutView.vue")
	// }
];
const router = createRouter({
	history: createWebHashHistory(),
	routes
});

export default router;
